// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ebedf3;
  border-radius: 0.42rem;
  justify-content: center;
}
@mixin svg-icon-color() {
  .edit {
    svg {
      g {
        [fill] {
          transition: fill 0.3s ease;
          fill: #1bc5bd !important;
        }
      }

      &:hover {
        g {
          [fill] {
            transition: fill 0.3s ease;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  aside {
    margin-top: 20px;
  }
  .product-author {
    display: block;
  }
  .product-title-section h1 {
    font-size: 20px;
    font-weight: 600;
  }
}

.product-score {
  // display: flex;
  // justify-content: space-around;
  // align-items: center;
}
.product-score .score-item {
  text-align: center;
  padding: clamp(1rem, 1.87vmax, 15px);
  border: 1.5px solid #3699ff;
  border-radius: 15px;
  flex: 0 1 160px;
  font-size: 16px;
  font-weight: 600;
  color: #3699ff;
}
.product-score .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: 800;
  line-height: 1;
  letter-spacing: -0.04rem;
  margin-bottom: 10px;
}
.product-score .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.pagination_ ul li button {
  border: none !important;
}
button:disabled {
  cursor: not-allowed !important;
}

.sidee {
  margin-bottom: 25px;
  border: 1px solid #f3f6f9;
  border-radius: 25px;
}
// input[type="date"]::-webkit-calendar-picker-indicator {
//   background: transparent;
//   bottom: 0;
//   color: transparent;
//   cursor: pointer;
//   height: auto;
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: auto;
// }
